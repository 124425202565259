// 引入封装的axios请求文件
import req from '../utils/request.js'
// 注册第一步
export function registerFirst (params) {
  return req.post('register1', params)
}
// 注册第二步
export function registerSecond (params) {
  return req.post('register2', params)
}
// 获取code
export function getcode (params) {
  return req.post('getEmailCode', params)
}
// 登录
export function login (params) {
  return req.post('login', params)
}
// 重置密码
export function reset (params) {
  return req.post('resetPassword', params)
}
// 获取用户信息
export function userInfo (params) {
  return req.post('info', params)
}

// 上传头像
export function uploadImg (params) {
  return req.post('uploadImage', params)
}

export function transaction (params) {
  return req.post('transactions', params)
}

// 修改用户信息
export function updateInfo (params) {
  return req.post('updateInfo', params)
}

// 修改密码
export function changePwd (params) {
  return req.post('changePwd', params)
}

// 验证email code
export function verifyEmailCode (params) {
  return req.post('verifyEmailCode', params)
}

// 退出登录
export function logout (params) {
  return req.post('logout', params)
}

// 管理员登录
export function mangeLogin (params) {
  return req.post('mangeLogin', params)
}
