import { createApp, reactive } from 'vue'
import myLoad from '@/components/Loading.vue'
const info = reactive({
  show: false,
  title: 'loading....'
})
const $loading = createApp(myLoad, { info }).mount(document.createElement('div'))
const load = {
  show (title) {
    info.show = true
    info.title = title
    document.body.appendChild($loading.$el)
  },
  hide () {
    info.show = false
  }
}

export { load }
