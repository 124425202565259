<template>
  <div class="loading" v-show="info.show">
    <div class="load-box">
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
        class="arco-icon arco-icon-loading arco-icon-spin" stroke-width="4" stroke-linecap="butt"
        stroke-linejoin="miter">
        <path d="M42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6"></path>
      </svg>
      <span>{{ info.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    info: {}
  }
}
</script>

<style lang="less" scoped>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999999999999;

  .load-box {
    color: #13B966;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
