<template>
  <div class="myProfile">
    <div class="profile-title">Display Options</div>
    <div class="profile-cont">
      <a-form ref="formOptiiion" :model="options" layout="vertical">
        <a-card title="Log In Option">
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-form-item field="login_directiy" label="Log in directly to">
                <a-select v-model="options.login_directiy">
                  <a-option value="/workcenter">Work Bench(Default)</a-option>
                  <a-option value="/usercenter/myprofile">Account-My Profile</a-option>
                  <a-option value="/usercenter/wallet">Account-Wallet</a-option>
                  <a-option value="/usercenter/statistics">Account-Statistics</a-option>
                  <a-option value="/shipment">Shipment</a-option>
                  <a-option value="/order/add/addneworder">Order-Add New</a-option>
                  <a-option value="/order/import/importorders">Order-Import Orders</a-option>
                  <a-option value="/order/list/orderlist">Order-Order List</a-option>
                  <a-option value="/products/addproducts">Products-Add New</a-option>
                  <a-option value="/products/productslist">Products-Products List</a-option>
                  <a-option value="/products/importpro/importproducts">Products-Import Produts</a-option>
                  <a-option value="/address/addaddress">Address-Add Address</a-option>
                  <a-option value="/address/addresslist">Address-Address List</a-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card title="Time & Date" style="margin-top:24px;">
          <a-row :gutter="[20, 20]">
            <a-col :span="8">
              <a-form-item field="time_format" label="Time Format">
                <a-radio-group v-model="options.time_format">
                  <a-radio v-for="item, k, i in timeformat" :value="i + 1" :key="k">{{ item }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="data_format" label="Date Format">
                <a-select v-model="options.data_format">
                  <a-option v-for="item, k, i in dateformat" :value="i + 1" :key="k">{{ item }}</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="time_zone" label="Time Zone">
                <a-select v-model="options.time_zone">
                  <a-option v-for="val, k in timeZone" :value="k" :key="k">{{ val }}</a-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
        <div class="save-btn">
          <a-button class="normal-btn" type="primary" shape="round" @click="submimt"> Update</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import local from '@/utils/local'
import { Notification } from '@arco-design/web-vue'
// 引入api中的接口
import { userInfo, updateInfo } from '@/api/account.js'
// 使用loading
import { load } from '@/utils/loading.js'
const options = reactive({
  login_directiy: '',
  time_format: '',
  data_format: '',
  time_zone: ''
})
// 获取time zone
const timeZone = JSON.parse(local.get('configInfo')).time_zones
const dateformat = JSON.parse(local.get('configInfo')).date_format
const timeformat = JSON.parse(local.get('configInfo')).time_format
// 获取用户信息回填
const userData = reactive({ val: {} })
const getUserInfo = async () => {
  const msg = await userInfo({})
  if (msg.code === 0) {
    userData.val = msg.data
    options.login_directiy = msg.data.login_directly_to
    options.time_format = msg.data.time_format
    options.data_format = msg.data.date_format
    options.time_zone = msg.data.timezone
    local.set('userInfo', JSON.stringify(msg.data))
  }
}
getUserInfo()
// 提交修改
const submimt = async () => {
  if (options.login_directiy === userData.val.login_directly_to &&
    options.time_format === userData.val.time_format &&
    options.data_format === userData.val.date_format &&
    options.time_zone === userData.val.timezone) {
    Notification.error({
      title: 'Error',
      content: 'No update content.'
    })
  } else {
    load.show('Saving Change')
    const msg = await updateInfo({
      type: 4,
      login_directly_to: options.login_directiy,
      time_format: options.time_format,
      date_format: options.data_format,
      time_zone: options.time_zone
    })
    if (msg.code === 0) {
      load.hide()
      Notification.success({
        title: 'Success',
        content: 'Change saved.'
      })
      getUserInfo()
    } else {
      load.hide()
      Notification.error({
        title: 'Error',
        content: msg.message
      })
    }
  }
}
</script>

<style lang="less" scoped>
.profile-title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  margin: 18px 24px 18px 48px;
}

.profile-cont {
  margin: 0 180px;
}

.save-btn {
  margin-top: 24px;
  text-align: right;
}
</style>
